// Global vars
// Colors
$gray-100: #f7f7f7;
$gray-200: #e7e7e7;
$gray-300: #d1d1d1;
$gray-400: #b0b0b0;
$gray-500: #888888;
$gray-600: #777777;
$gray-700: #4f4f4f;
$gray-800: #454545;
$gray-900: #3d3d3d;
$gray-1000: #070707;

$yellow-100: #ffd700;
$yellow-200: #febe10;

$green-100: #00cb77;

$blue-100: #f1f2f9;
$blue-200: #d5d7ee;
$blue-300: #b9bce3;
$blue-400: #9da1d7;
$blue-500: #8286cc;
$blue-600: #555bc1;
$blue-700: #4a51b5;
$blue-800: #3e4499;
$blue-900: #373c87;
$blue-1000: #25285b;
$blue-1100: #0d0f2a;

$red-100: #fff7f6;
$red-200: #ffebe8;
$red-300: #ffdbd7;
$red-400: #ffcbc5;
$red-500: #ffbbb4;
$red-600: #fba69e;
$red-700: #f28a82;
$red-800: #ff4a49;
$red-900: #f1393c;
$red-1000: #d61726;

$brand-primary: $blue-900;
$brand-secondary: #5074ef;
$brand-pink: #ff77a7;

:root {
  --afr-primary: #{$brand-primary};
  --afr-white: #fff;
  --afr-gray-100: #{$gray-100};
  --afr-gray-200: #{$gray-200};
  --afr-gray-300: #{$gray-300};
  --afr-gray-400: #{$gray-400};
  --afr-gray-500: #{$gray-500};
  --afr-gray-600: #{$gray-600};
  --afr-gray-700: #{$gray-700};
  --afr-gray-900: #{$gray-900};
  --afr-gray-1000: #{$gray-1000};

  --afr-yellow-100: #{$yellow-100};
  --afr-yellow-200: #{$yellow-200};

  --afr-color-green-100: #{$green-100};

  --afr-blue-100: #{$blue-100};
  --afr-blue-200: #{$blue-200};
  --afr-blue-300: #{$blue-300};
  --afr-blue-400: #{$blue-400};
  --afr-blue-500: #{$blue-500};
  --afr-blue-600: #{$blue-600};
  --afr-blue-700: #{$blue-700};
  --afr-blue-800: #{$blue-800};
  --afr-blue-900: #{$blue-900};
  --afr-blue-1000: #{$blue-1000};
  --afr-blue-1100: #{$blue-1100};

  --afr-red-100: #{$red-100};
  --afr-red-200: #{$red-200};
  --afr-red-300: #{$red-300};
  --afr-red-400: #{$red-400};
  --afr-red-500: #{$red-500};
  --afr-red-600: #{$red-600};
  --afr-red-700: #{$red-700};
  --afr-red-800: #{$red-800};
  --afr-red-900: #{$red-900};
  --afr-red-1000: #{$red-1000};
}

// Spacings
$spacing-micro: 4px;
$spacing-small: 8px;
$spacing-medium: 12px;
$spacing-base: 16px;
$spacing-large: 24px;
$spacing-xlarge: 32px;

// Font Sizes
$font-size-micro: 0.625rem;
$font-size-small: 0.8125rem;
$font-size-base: 1rem;
$font-size-medium: 1.25rem;
$font-size-large: 1.5rem;
$font-size-xlarge: 2rem;

// Font weights
$font-weight-regular: 400;
$font-weight-semi: 500;
$font-weight-bold: 700;
$font-weight-bolder: 900;

// Border
$border-radius-rounded: 4px;
$border-radius-rounded-large: 7px;
$border-radius-circle: 777px;

// Layout
$layout-top-header-height: 60px;
$navigation-width: 216px;
$navigation-width-collapsed: 60px;

// Other
$outline: rgb($brand-primary, 0.7);

// Table
$table-column-width-tiny: minmax(80px, 1fr);
$table-column-width-tiny-fixed: 80px;

$table-column-width-small: minmax(120px, 1fr);
$table-column-width-small-fixed: 120px;

$table-column-width-medium: minmax(140px, 1fr);
$table-column-width-medium-fixed: 140px;

$table-column-width-base: minmax(160px, 1fr);
$table-column-width-base-fixed: 160px;

$table-column-width-large: minmax(200px, 1fr);
$table-column-width-large-fixed: 200px;

$table-column-width-xlarge: minmax(280px, 1fr);
$table-column-width-xlarge-fixed: 280px;

$table-column-width-xxlarge: minmax(360px, 1fr);
$table-column-width-xxlarge-fixed: 360px;
