@use 'sass:color';
@use 'app/css/vars' as *;
@use 'app/css/reset' as *;

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

::selection {
  background: $brand-primary;
  color: #fff;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--afr-gray-400);
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--afr-gray-400);
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--afr-gray-400);
}
:-moz-placeholder {
  /* Firefox 18- */
  color: var(--afr-gray-400);
}

[hidden] {
  display: none !important;
}

body {
  margin: 0;
  font-optical-sizing: auto;
  font-family: 'Roboto', sans-serif;
  font-size: 100%;
  font-weight: var(--afr-font-weight-regular);
  font-style: normal;
  color: var(--afr-text-base);
  max-width: 100vw;
  overflow-x: hidden;
  line-height: 1.3;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: var(--afr-link);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

button:focus-visible {
  outline: 2px solid var(--afr-outline);
}

p {
  margin-bottom: $spacing-base;
}

:root {
  // Fonts
  // Sizes
  --afr-font-base: #{$font-size-base};
  --afr-font-micro: #{$font-size-micro};
  --afr-font-small: #{$font-size-small};
  --afr-font-medium: #{$font-size-medium};
  --afr-font-large: #{$font-size-large};
  --afr-font-xlarge: #{$font-size-xlarge};
  // Weight
  --afr-font-weight-regular: #{$font-weight-regular};
  --afr-font-weight-semi: #{$font-weight-semi};
  --afr-font-weight-bold: #{$font-weight-bold};
  --afr-font-weight-bolder: #{$font-weight-bolder};

  // Spacings
  --afr-space-base: #{$spacing-base};
  --afr-space-micro: #{$spacing-micro};
  --afr-space-small: #{$spacing-small};
  --afr-space-medium: #{$spacing-medium};
  --afr-space-large: #{$spacing-large};
  --afr-space-xlarge: #{$spacing-xlarge};

  // Border
  --afr-border-radius-rounded: #{$border-radius-rounded};
  --afr-border-radius-rounded-large: #{$border-radius-rounded-large};
  --afr-border-radius-circle: #{$border-radius-circle};

  // Links
  --afr-link: #{$brand-secondary};
  --afr-link-visited: #{$brand-secondary};
  --afr-link-pressed: #{$brand-secondary};

  // Labels
  --afr-label: var(--afr-gray-900);
  --afr-label-bottom-space: #{$spacing-micro};
  --afr-label-font-size: #{$font-size-base};
  --afr-label-font-weight: #{$font-weight-semi};

  // Buttons
  --afr-button-action-bg: #{$brand-primary};
  --afr-button-action-bg-hover: var(--afr-blue-1000);
  --afr-button-action-bg-disabled: var(--afr-blue-600);

  --afr-button-outline-bg: #fff;
  --afr-button-outline-bg-hover: #{color.adjust(#fff, $lightness: -7%)};
  --afr-button-outline-border: var(--afr-gray-200);

  --afr-button-ghost-bg: transparent;
  --afr-button-ghost-bg-hover: #{color.adjust(#fff, $lightness: -7%)};

  --afr-button-destructive-bg: var(--afr-red-1000);
  --afr-button-destructive-bg-hover: #{color.adjust($red-900, $lightness: -18%)};

  --afr-button-link-color: #{$brand-secondary};
  --afr-button-link-bg: transparent;
  --afr-button-link-bg-hover: #{color.adjust(#fff, $lightness: -7%)};

  // Text
  --afr-text-base: var(--afr-gray-1000);

  // Input
  --afr-outline: #{$outline};
  --afr-input-text-error: var(--afr-red-1000);
  --afr-input-text: var(--afr-gray-1000);
  --afr-input-text-disabled: var(--afr-gray-600);
  --afr-input-text-no-value: var(--afr-gray-700);
  --afr-input-border: var(--afr-gray-200);
  --afr-input-border-focus: #{$outline};
  --afr-input-border-error: var(--afr-red-1000);
  --afr-input-border-radius: #{$border-radius-rounded};
  --afr-input-padding: #{$spacing-medium};
  --afr-input-disabled-bg: var(--afr-gray-100);
  --afr-input-readonly-bg: var(--afr-gray-100);

  // Tooltip
  --afr-tooltip-bg: var(--afr-gray-1000);
  --afr-tooltip-bg-light: #fff;
  --afr-tooltip-bg-blue: var(--afr-blue-700);
  --afr-tooltip-text: #fff;
  --afr-tooltip-text-light: var(--afr-gray-1000);
  --afr-tooltip-padding: #{$spacing-small};
  --afr-tooltip-max-width: 300px;

  // Menu
  --afr-menu-bg-hover: #{color.adjust(#fff, $lightness: -7%)};
  --afr-menu-item-bg-hover: #{color.adjust(#fff, $lightness: -7%)};
  --afr-menu-padding: #{$spacing-small} #{$spacing-base};
  --afr-menu-item-padding: #{$spacing-small} #{$spacing-base};
  --afr-menu-outline-border: var(--afr-gray-200);

  // Navigation Menu
  --afr-nav-menu-item-bg-hover: var(--afr-blue-900);
  --afr-nav-menu-item-bg-selected: var(--afr-blue-900);
  --afr-nav-menu-item-border-radius: #{$border-radius-rounded};
  --afr-nav-menu-item-padding: #{$spacing-small};
  --afr-nav-menu-item-text: var(--afr-blue-400);
  --afr-nav-menu-item-text-selected: #fff;
  --afr-nav-menu-item-icon-selected: #fff;
  --afr-nav-menu-item-text-hover: #fff;
  --afr-nav-menu-item-icon-hover: #fff;

  // Select
  --afr-select-bg-hover: #{color.adjust(#fff, $lightness: -7%)};
  --afr-select-item-bg-hover: #{color.adjust(#fff, $lightness: -7%)};
  --afr-select-selected: #{color.adjust(#fff, $lightness: -4%)};
  --afr-select-item-disabled: var(--afr-gray-300);
  --afr-select-item-padding: #{$spacing-medium} #{$spacing-base};

  // Shadow
  --afr-shadow-100: rgba(0, 0, 0, 0.25) 0px 5px 5px -3px,
    rgba(0, 0, 0, 0.2) 0px 8px 10px 1px, rgba(0, 0, 0, 0.15) 0px 3px 14px 2px;
  --afr-shadow-200: 0px 0px 14px 0px rgba(0, 0, 0, 0.15);

  // Container
  --afr-container-border: var(--afr-gray-200);
  --afr-container-border-radius: #{$border-radius-rounded-large};
  --afr-container-gap: #{$spacing-medium};
  --afr-container-gap-large: #{$spacing-large};
  --afr-container-gap-small: #{$spacing-small};
  --afr-container-padding: #{$spacing-base};
}
